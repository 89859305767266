import React, { } from 'react';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';


type Props = {
  children?: React.ReactNode,
  className?: string
};

// Component
const MaxWidthWrapper: React.FC<Props> = (props) => {
  return (
    <StyledMaxWidthWrapper className={`maxWidthWrapper ${props.className ?? ''}`}>
      {props.children}
    </StyledMaxWidthWrapper>
  );
};

export default MaxWidthWrapper;


const StyledMaxWidthWrapper = styled.div`

  width: 100%;
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${bp(3)}) {

  }

`;
