import React, { } from 'react';
import Link from 'next/link';
import { trpc } from 'utils/trpc';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';

import theme, {bp, color} from 'settings/theme';
import WalletButton from 'components/layout/header/headerWallet';

const Header: React.FC<{}> = (props) => {

  const layout = useLayout();

  // Get Cart
  const cartQuery = trpc.carts.getCurrent.useQuery({store_id: 10});
  const cart = cartQuery.data;

  return (
    <StyledHeader className="header">

      {/* Fake header for fixed positioning */}
      <div className="header-fake" />

      {/* Fixed header */}
      <div className="header-float">
        <MaxWidthWrapper className="header-wrapper">
          <div className="header-main">
            <Link href="/">
              <img src="/hj/images/logo-light.svg" alt="HighJinx Logo" />
            </Link>
          </div>
          <div className="header-right">

            {/* Social Buttons */}
            <div className="header-socialButtons">
              <Link href="https://twitter.com/Highjinx_so" target="_blank">
                <div className="header-socialButton-inner">
                  <img src="/images/icons/icon-twitter-white.svg" alt="Twitter" />
                </div>
              </Link>
              <Link href="https://discord.gg/gobi" target="_blank">
                <div className="header-socialButton-inner">
                  <img src="/images/icons/icon-discord-white.svg" alt="Tritter" />
                </div>
              </Link>
            </div>

            {/* Wallet button */}
            <div className="header-walletButton">
              <WalletButton />
            </div>
            
            {/* Bag button */}
            <Link href="/your-shopping-bag" className="header-bagButton">
              <img src="/images/icons/icon-bag-white.svg" alt="Menu" />
              <span className="header-bagButton-text">
                Your Bag <strong>({cart?.item_count ?? 0})</strong>
              </span>
              {((cart?.item_count ?? 0) > 0) && 
                <span className="header-bagButton-number">
                  {cart?.item_count}
                </span>
              }
              
            </Link>

          </div>
        </MaxWidthWrapper>
      </div>

    </StyledHeader>
  );

};

export default Header;


// Header
const StyledHeader = styled.header`

  .header-fake{
    height: 3.5rem;
  }

  .header-float{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 3.5rem;
    background-color: ${color.offGray(8)};
  }

  .header-wrapper{
    display: flex;
    align-items: center;
  }

  .header-main {

    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 3.5rem;
    padding-left: 0.5rem;

    img{
      width: auto;
      height: 2.25rem;
    }
    
  }

  .header-right{
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
    width: 7rem;
    height: 100%;
  }

  .header-socialButtons{

    display: flex;
    align-items: center;

    a{

      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;

      .header-socialButton-inner{

        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: ${color.offGray(8)};
        transition: background-color 0.15s ease-out;

        &:hover{
          background-color: ${color.offGray(20)};
        }

        img{
          width: 1.25rem;
          height: 1.25rem;
        }

      }

    }

  }

  .header-bagButton{

    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: calc(3.5rem - 1px);
    background-color: ${color.gray(10)};
    transition: background-color 0.15s ease-out;

    &:hover{
      z-index: 1;
      background-color: ${color.gray(18)};
    }

    img{
      width: 1.625rem;
      height: 1.625rem;
    }

    .header-bagButton-text{
      display: none;
    }

    .header-bagButton-number{
      position: absolute;
      top: 0.6rem;
      right: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1rem;
      min-width: 1rem;
      padding: 0 0.25rem;
      color: black;
      font-size: 0.75rem;
      font-weight: 600;
      border-radius: 50%;
      background-color: ${color.green(50)};
    }

  }

  .header-walletButton{

    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    padding: 0 0.5rem;

  }

  @media (min-width: ${bp(2)}) {
    .header-bagButton{
      right: 0;
    }
  }


  @media (min-width: ${bp(3)}) {

    .header-left{
      display: none;
    }

    .header-main{
      flex: 0 0 auto;
      justify-content: flex-start;
      padding-left: 1.5rem;
    }

    .header-right{
      flex: 1 1 auto;
      width: auto;
    }

    .header-bagButton{

      display: flex;
      gap: 0.5rem;
      width: auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: ${color.gray(95)};
      font-size: 0.925rem;
      font-weight: 300;
      border-left: solid 1px ${color.gray(10)};

      .header-bagButton-text{
        display: block;
        font-weight: 300;
      }

      .header-bagButton-number{
        display: none
      }

    }

  }

  @media (min-width: 100rem) {

    .header-bagButton{
      border-right: solid 1px ${color.gray(10)};
    }

  }

`