import React, { } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import MaxWidthWrapper from 'components/layout/maxWidthWrapper';

import theme, {bp, color} from 'settings/theme';

type Props = {
  theme?: string,
  backgroundColor?: string
}

const Footer: React.FC<Props> = (props) => {

  return (
    <StyledFooter theme={props.theme} backgroundColor={props.backgroundColor} className="footer">

      <MaxWidthWrapper className="footer-wrapper">
        <div className="footer-inner">
          <div className="footer-layout">
            <div className="footer-left">
              <div className="footer-copyright">
                &copy;2023 Highjinx. All rights reserved.<br />
                POWERED BY GOBI LABS
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-logo">
                <img src={props.theme === 'dark' ? '/hj/images/logo-light.svg' : '/hj/images/logo-dark.svg'}  alt="HighJinx Logo" />
              </div>
            </div>
          </div>
        </div>
      </MaxWidthWrapper>

    </StyledFooter>
  );

};

Footer.defaultProps = {
  theme: 'dark'
};

export default Footer;


// Footer
const StyledFooter = styled.footer<{theme: string, backgroundColor?: string}>`

  
  background-color: ${props => props.backgroundColor ?? color.offGray(8)};

  .footer-inner{
    margin: 0 1rem;
    padding: 1rem 0;
    border-top: solid 1px ${props => props.theme === 'dark' ? color.gray(75) : color.gray(10)};
  }

  .footer-layout{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .footer-left{
    flex: 1 1 auto;
  }

  .footer-right{
    flex: 0 0 auto;
  }

  .footer-logo{
    img{
      width: 2.75rem;;
      height: auto;
    }
  }

  .footer-copyright{
    color: ${props => props.theme === 'dark' ? color.gray(90) : color.gray(20)};
    font-size: 0.8rem;
    font-weight: 450;
    line-height: 1.5;
  }


  @media (min-width: ${bp(3)}) {



  }

`